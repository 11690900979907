// See README.md.
import type { RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby";
import { ReactElement, useEffect, useMemo } from "react";

import { useUserGetQuery } from "../api";
import { LoadingScreen } from "../components";
import { useAppSelector } from "../store";
import { logger } from "../utils";
import { getSessionRoute, isSessionRoute } from "./sessionRoute";
import { sessionSelectCurrent } from "./sessionSlice";
import type { SessionUser } from "./SessionUser";

export type SessionLoaderProps = Readonly<{
  /**
   * The required authentication status of the user to view this route.
   *
   * - Routes marked `authenticated` require the user to be authenticated.
   * - Routes marked `public` can be viewed by anyone regardless of
   *   authentication status.
   * - Routes marked `unauthenticated` can only be viewed by users who are
   *   authenticated.
   */
  audience: "authenticated" | "public" | "unauthenticated";

  /**
   * The React node to render once authentication status and session checks
   * have been performed.
   */
  children: ReactElement;

  location: Exclude<RouteComponentProps["location"], undefined>;
}>;

/**
 * Session loading indicator.
 */
function LoadingIndicator(): JSX.Element | null {
  return <LoadingScreen />;
}

/**
 * This loader stage handles responding to Firebase out of band account
 * management operations.
 *
 * If the `mode` and `oobCode` query parameters are present, it will redirect
 * to the appropriate page, otherwise it will render `children`.
 */
function SessionLoaderStage0(props: SessionLoaderProps): ReactElement {
  const { location } = props;

  /**
   * Firebase _out of band_ account management callback query parameters.
   *
   * These are supplied by the Firebase Authentication server when redirecting
   * back to the app after account management operations like password resets or
   * email verifications.
   */
  const oobQueryParameters = useMemo(() => {
    const queryParameters = new URLSearchParams(location.search);
    const mode = queryParameters.get("mode");
    const oobCode = queryParameters.get("oobCode");
    return mode && oobCode ? ({ mode, oobCode } as const) : null;
  }, [location.search]);

  // Handle the authentication callback query parameter.
  useEffect(() => {
    switch (oobQueryParameters?.mode) {
      // Do nothing. Children will be rendered.
      case undefined: {
        return;
      }

      // Handle the redirect back from the password reset email by redirecting
      // to the password reset page. A loading indicator is rendered in the
      // meantime.
      case "resetPassword": {
        const { oobCode } = oobQueryParameters;
        navigate(getSessionRoute("resetPassword", { oobCode }));
        return;
      }

      // Handle the redirect back from the email verification email by
      // redirecting to the account verified page. A loading indicator is
      // rendered in the meantime.
      case "verifyEmail": {
        const { oobCode } = oobQueryParameters;
        navigate(getSessionRoute("accountVerified", { oobCode }));
        return;
      }
    }
  }, [oobQueryParameters]);

  return oobQueryParameters ? (
    <LoadingIndicator />
  ) : (
    <SessionLoaderStage1 {...props} />
  );
}

/**
 * This loader stage handles checking the authentication status of the user and
 * their access to the current route.
 */
function SessionLoaderStage1(props: SessionLoaderProps): ReactElement {
  const { audience, children } = props;
  const sessionCurrent = useAppSelector(sessionSelectCurrent);

  useEffect(() => {
    if (sessionCurrent.status !== "success") return;

    if (audience === "unauthenticated" && sessionCurrent.user) {
      navigate(getSessionRoute("profile"), { replace: true });
      return;
    }

    if (audience === "authenticated" && !sessionCurrent.user) {
      navigate(getSessionRoute("signIn"));
      return;
    }
  }, [audience, sessionCurrent]);

  // Immediately return the children if the route is public, i.e., it doesn't
  // mater if the user is authenticated or not.
  if (audience === "public") return children;

  // Display a loading indicator while the session is loading.
  // TODO: Figure out how to handle a loading error without ending up in an
  // unclearable state.
  if (sessionCurrent.status !== "success") return <LoadingIndicator />;

  // If the user is visiting a route for only unauthenticated users, and they
  // are authenticated, display the loading indicator while the profile page
  // redirect is in progress.
  if (audience === "unauthenticated") {
    if (sessionCurrent.user) return <LoadingIndicator />;
    else return children;
  }

  // If the user is authenticated, display the next stage, otherwise display the
  // loading indicator while the redirect is in progress.
  return sessionCurrent.user ? (
    <SessionLoaderStage2 {...props} sessionUser={sessionCurrent.user} />
  ) : (
    <LoadingIndicator />
  );
}

/**
 * This stage handles redirections to pages which collect missing profile data.
 *
 * It will redirect to the update profile page to verify the user's email
 * address.
 *
 * It will redirect to the tutorial page to so the user can see the tutorial.
 */
function SessionLoaderStage2({
  children,
  location,
  sessionUser,
}: SessionLoaderProps & {
  readonly sessionUser: SessionUser;
}): ReactElement {
  /*
    Possible request statuses:
    - isError
    - isFetching
    - isLoading
    - isSuccess
    - isUninitialized
   */
  const userGetQuery = useUserGetQuery();

  /**
   * Is `true` when the query is currently in an error state and it is a 404
   * response. This would indicate that the user profile has not yet been
   * created.
   */
  const is404Error = useMemo((): boolean => {
    return (
      userGetQuery.isError &&
      !!userGetQuery.error &&
      "kind" in userGetQuery.error &&
      userGetQuery.error.kind === "errorResponse" &&
      userGetQuery.error.status === 404
    );
  }, [userGetQuery.error, userGetQuery.isError]);

  const hasVerifiedEmail = useMemo((): boolean => {
    return sessionUser.provider === "password"
      ? sessionUser.emailVerified
      : true;
  }, [sessionUser]);

  const hasViewedTutorial = useMemo((): boolean => {
    return userGetQuery.data?.has_viewed_tutorial || false;
  }, [userGetQuery.data?.has_viewed_tutorial]);

  useEffect(() => {
    // If the user is signed in but their profile hasn't been created yet,
    // redirect to the update profile page.
    if (is404Error) {
      if (!isSessionRoute("updateProfile", location.pathname)) {
        navigate(getSessionRoute("updateProfile"));
      }
      return;
    }

    if (!userGetQuery.data) return;

    // If the user hasn't verified their email, redirect to the update profile
    // page.
    if (!hasVerifiedEmail) {
      if (!isSessionRoute("updateProfile", location.pathname)) {
        navigate(getSessionRoute("updateProfile"));
      }
      return;
    }

    // If the user hasn't viewed the tutorial, redirect to the tutorial page.
    if (!hasViewedTutorial) {
      if (!isSessionRoute("tutorial", location.pathname)) {
        navigate(getSessionRoute("tutorial"));
      }
      return;
    }
  }, [
    hasVerifiedEmail,
    hasViewedTutorial,
    is404Error,
    location.pathname,
    userGetQuery.data,
  ]);

  // If the request has returned a 404 error, then display a loading indicator
  // while the redirect to the update profile page is in progress.
  if (is404Error) {
    return isSessionRoute("updateProfile", location.pathname) ? (
      children
    ) : (
      <LoadingIndicator />
    );
  }

  // If the request hasn't completed successfully, display a loading indicator.
  if (!userGetQuery.data) return <LoadingIndicator />;

  // If the user has not verified their email address, display a loading
  // indicator while the redirect to the update profile page is in progress.
  if (!hasVerifiedEmail) {
    return isSessionRoute("updateProfile", location.pathname) ? (
      children
    ) : (
      <LoadingIndicator />
    );
  }

  // If the user has not viewed the tutorial, display a loading indicator while
  // the redirect to the tutorial page is in progress.
  if (!hasViewedTutorial) {
    return isSessionRoute("tutorial", location.pathname) ? (
      children
    ) : (
      <LoadingIndicator />
    );
  }

  return children;
}

/**
 * Handles waiting for the Firebase session to load, and for authenticated
 * routes, the user profile.
 *
 * It handles responding to callbacks from Firebase out of band account
 * management operations, like password resets and email verification.
 *
 * It handles redirecting users to appropriate sign in or account detail
 * gathering pages depending on the `audience` property of the route.
 *
 * The provided `children` will be rendered once authentication checks and
 * session loading have been completed.
 */
export function SessionLoader(props: SessionLoaderProps): ReactElement {
  const { location } = props;

  useEffect(() => {
    logger.log("location:", location);
  }, [location]);

  return <SessionLoaderStage0 {...props} />;
}
