import type { RouteComponentProps } from "@reach/router";
import React from "react";

import { SessionLoader } from "../session";
import { assertDefined } from "../utils";

function NotFound({ location }: RouteComponentProps) {
  assertDefined(location);

  return (
    <SessionLoader audience="public" location={location}>
      <main>
        <p>Sorry, page not found!</p>
      </main>
    </SessionLoader>
  );
}

export default NotFound;
