import type { ReadonlyDeep } from "type-fest";

/**
 * A utility type that enforces the shape of the `SessionRouteConfigMap` type.
 */
type CreateSessionRouteConfigMap<
  SessionRouteConfigMap extends Record<
    string,
    {
      path: `/${string}`;
      queryParameters?: string[];
    }
  >,
> = ReadonlyDeep<SessionRouteConfigMap>;

/**
 * A mapping of routes to their config.
 */
type SessionRouteConfigMap = CreateSessionRouteConfigMap<{
  accountVerified: {
    path: "/account-verified";
    queryParameters: ["oobCode"];
  };
  curriculum: {
    path: "/curriculum";
  };
  forgotPassword: {
    path: "/forget-password";
  };
  profile: {
    path: "/";
  };
  profileAnchorPrivacy: {
    path: "/profile/#privacy";
  };
  profileAnchorProfile: {
    path: "/profile/#your-profile";
  };
  profileAnchorRewards: {
    path: "/profile/#your-rewards";
  };
  profileAnchorTerms: {
    path: "/profile/#terms";
  };
  resetPassword: {
    path: "/reset-password";
    queryParameters: ["oobCode"];
  };
  signIn: {
    path: "/login";
  };
  signUp: {
    path: "/sign-up";
  };
  tutorial: {
    path: "/tutorial";
  };
  updateProfile: {
    path: "/update-profile";
  };
}>;

/**
 * A mapping of routes to their paths.
 */
type SessionRouteToPathMap = {
  [P in keyof SessionRouteConfigMap]: SessionRouteConfigMap[P]["path"];
};

export type SessionRoutePath =
  SessionRouteToPathMap[keyof SessionRouteToPathMap];

const sessionRouteToPathMap: SessionRouteToPathMap = {
  accountVerified: "/account-verified",
  curriculum: "/curriculum",
  forgotPassword: "/forget-password",
  profile: "/",
  profileAnchorPrivacy: "/profile/#privacy",
  profileAnchorProfile: "/profile/#your-profile",
  profileAnchorRewards: "/profile/#your-rewards",
  profileAnchorTerms: "/profile/#terms",
  resetPassword: "/reset-password",
  signIn: "/login",
  signUp: "/sign-up",
  tutorial: "/tutorial",
  updateProfile: "/update-profile",
};

/**
 * Returns the path for the given route.
 *
 * It enforces that required query parameters are provided.
 */
export function getSessionRoute<Route extends keyof SessionRouteConfigMap>(
  ...[route, queryParameters]: SessionRouteConfigMap[Route] extends {
    queryParameters: infer QueryParameters extends readonly string[];
  }
    ? [
        route: Route,
        queryParameters: Readonly<Record<QueryParameters[number], string>>,
      ]
    : [route: Route]
): string {
  if (queryParameters) {
    const queryString = new URLSearchParams(queryParameters).toString();
    return `${sessionRouteToPathMap[route]}?${queryString}`;
  }
  return sessionRouteToPathMap[route];
}

/**
 * Returns whether or not the provided `pathname` corresponds to the specified
 * `route`.
 */
export function isSessionRoute<Route extends keyof SessionRouteConfigMap>(
  route: Route,
  pathname: string,
): boolean {
  return pathname.startsWith(sessionRouteToPathMap[route]);
}
